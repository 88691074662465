<template>
  <div class="relative">
    <ArrowButton
      :text="admin ? admin.description : 'admin'"
      :rotate="(folded ? '' : '-') + '90deg'"
      :background="admin ? 'white' : 'black'"
      :color="admin ? 'black' : 'white'"
      @onClick="toggleFolded"
    />
    <div v-if="!folded" class="flex flex-col w-full absolute top-11 z-10">
      <div
        v-for="admin in admins"
        :key="admin.id"
        class="custom-list-item cursor-pointer"
        @click="selectAdmin(admin)"
      >
        {{ admin.description }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ArrowButton from '@/components/shared/ArrowButton.vue'

export default {
  name: 'SeleccionAdmin',
  components: { ArrowButton },
  props: {
    admin: { type: Object, default: null },
    store: { type: String, default: '' }
  },
  data() {
    return {
      folded: true,
    }
  },

  computed: {
    ...mapGetters({ admins: 'admins/admins' })
  },

  async mounted() {
    if (!this.admins) {
      await this.getAdmins()
    }
  },

  methods: {
    ...mapActions({
      getAdmins: 'admins/getAdmins'
    }),

    selectAdmin(admin) {
      this.$store.commit(this.store + '/setAdmin', admin)
      this.toggleFolded()
    },

    toggleFolded() {
      this.folded = !this.folded
    }
  }
}
</script>
