<template>
  <div class="flex flex-col gap-1">
    <BackButton class="mb-1" :route="{ name: 'IngresarCliente'}" />

    <input
      v-model="name"
      type="text"
      placeholder="Nombre del cliente nuevo"
      class="px-4 py-2"
    />

    <SeleccionAdmin :admin="adminSeleccionado" store="ingresarNuevoCliente" />

    <ArrowButton
      text="ingresar"
      background="#cccccc"
      color="#000000"
      disabled-background="black"
      :disabled="!name || !adminSeleccionado"
      @onClick="submitAddClient"
    />

    <BlackModal v-if="showSuccessModal" text="ingreso correcto" @confirm="finalize" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import BackButton from '@/components/shared/BackButton.vue'
import ArrowButton from '@/components/shared/ArrowButton.vue'
import SeleccionAdmin from '@/components/director/SeleccionAdmin'
import BlackModal from '@/components/shared/BlackModal.vue'

export default {
  name: 'ClientesActuales',
  components: {
    BackButton,
    ArrowButton,
    SeleccionAdmin,
    BlackModal
  },

  data() {
    return {
      name: '',
      showSuccessModal: false
    }
  },

  computed: {
    ...mapGetters({ adminSeleccionado: 'ingresarNuevoCliente/admin' })
  },

  methods: {
    ...mapActions({ addClient: 'clients/add' }),

    ...mapMutations({ 
      setAdmin: 'ingresarNuevoCliente/setAdmin' 
    }),

    async submitAddClient() {
      const { success } = await this.addClient({
        name: this.name,
        idAdmin: this.adminSeleccionado.id
      })

      if (success) {
        this.showSuccessModal = true
      }
    },

    finalize() {
      this.showSuccessModal = false
      this.cleanState()
      this.$router.push({ name: 'IngresarCliente' })
    },

    cleanState() {
      this.setAdmin(null)
    }
  }
}
</script>

<style scoped>
input {
  @apply text-lg;
  font-family: ProximaNovaBold;
  letter-spacing: 1px;
  font-weight: bold;
}

input::placeholder {
  @apply text-xs text-black;
  font-family: ProximaNovaLightItalic;
}
</style>
